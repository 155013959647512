* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Lato', sans-serif !important;}

.text-pink {
  color: #ff5573;
}
.text-green {
  color: #00949B;
}
.cursor-pointer {
  cursor: pointer !important;
}


/* .Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 1px solid #CCC0C0;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
.Modal {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #CCC0C0;
  z-index: 1000;
} */

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

h2 {
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
  font-size: 32px;
  font-weight: 400;
  line-height: 39.2px;
  text-align: left;
  margin-bottom: 20px;
}

.mobile-input {
  width: 445px;
  height: 62px;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid #CCC0C0;
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}

.request-otp-btn {
  width: 445px;
  height: 62px;
  padding: 16px 24px;
  background-color: #FF5573;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.or-divider:before,
.or-divider:after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #CCC0C0;
}

.or-divider:before {
  margin-right: 10px;
}

.or-divider:after {
  margin-left: 10px;
}

.signin-google-btn {
  width: 445px;
  height: 62px;
  background-color: #fff;
  border: 1px solid #CCC0C0;
  border-radius: 8px;
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  padding-left: 24px;
  margin-bottom: 20px;
  cursor: pointer;
}

.signin-google-btn img {
  margin-right: 10px;
}

.signup-text {
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.otp-input {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  border: 1px solid #CCC0C0;
  border-radius: 8px;
  text-align: center;
  font-size: 20px;
}

.submit-btn {
  width: 445px;
  height: 62px;
  background-color: #FF5573;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.btn-primary {
  color: #fff !important;
  background-color: #FF5573 !important;;
  border-color: #FF5573 !important;;
}

.loader-container {
  position: fixed;
  inset: 0; /* This is shorthand for top: 0, right: 0, bottom: 0, left: 0 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.backdrop {
  position: fixed; /* ensure backdrop is also fixed and covers the whole screen */
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none; /* this makes sure clicks "go through" the backdrop */
}



.loader {
  z-index: 10000;
}
.border-top {
  border-top: 2px solid #000000 !important;
}

.auth-buttons{
  width: 180px !important;
}

.uni-details{
  background-color: #F7F7F7;
    padding: 1rem;
    margin-top: 10px;
    border-radius: 18px;
    box-shadow: 0px 2.67px 8.01px 0px rgba(0, 0, 0),
}
@media (max-width: 767px) {
  .container {
    padding: 0 10px;
  }

  .mobile-input,
  .request-otp-btn,
  .signin-google-btn,
  .submit-btn {
    width: 100%;
  }

  .otp-input {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  .Modal {
    width: 90%;
    max-width: 400px;
  }
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.f_menu {
  flex: 1 1 200px;
  margin-right: 20px;
}

@media (min-width: 769px) {
  .footer-content {
    flex-wrap: nowrap;
  }
}

.rendered-html-content h1, 
.rendered-html-content h2, 
.rendered-html-content h3, 
.rendered-html-content h4, 
.rendered-html-content h5, 
.rendered-html-content h6 {
  font-family: "Lato", sans-serif;
font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}

.rendered-html-content p {
  margin-bottom: 10px;
}

.rendered-html-content ul, 
.rendered-html-content ol {
  margin-left: 20px;
  margin-bottom: 10px;
}

.rendered-html-content img {
  max-width: 100%;
  height: auto;
}

.Modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #CCC0C0;
  z-index: 1000;
  width: 90%;
  max-width: 1000px;
  max-height: 70vh;
  overflow-y: auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ff5573;
  color: #ff5573;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.mobile-input,
.request-otp-btn,
.signin-google-btn,
.submit-btn {
  width: 100%;
  max-width: 445px;
}

.otp-input {
  width: 45px;
  height: 45px;
  margin-right: 5px;
  font-size: 18px;
}

@media (max-width: 767px) {
  .Modal {
    padding: 15px;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .mobile-input,
  .request-otp-btn,
  .signin-google-btn,
  .submit-btn {
    height: 50px;
    font-size: 16px;
    line-height: 20px;
  }

  .otp-input {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .signup-text {
    font-size: 16px;
    line-height: 20px;
  }
}

.img-fluid-login {
  max-width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .img-fluid-login {
    max-width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.auth-buttons {
  width: 100% !important;
  max-width: 180px !important;
}

@media (max-width: 767px) {
  .auth-buttons {
    max-width: 100% !important;
  }
}

@media (max-width: 350px) {
  .Modal {
    padding: 10px;
  }

  h2 {
    font-size: 20px;
    line-height: 26px;
  }

  .mobile-input,
  .request-otp-btn,
  .signin-google-btn,
  .submit-btn {
    height: 45px;
    font-size: 14px;
    line-height: 18px;
  }

  .otp-input {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }

  .signup-text {
    font-size: 14px;
    line-height: 18px;
  }
}