/* Base Styles */
* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  overflow-x: hidden;
}
 .app-container {
     display: flex;
     flex-direction: column;
     min-height: 100vh;
   }
   .content-wrapper {
     flex: 1;
   }

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #fd6376;
}
.profile-tab .nav-pills {
  border-bottom: 1.5px solid #ccc !important;
}
.section-padding {
  padding-top: 7rem !important;
}
.universsity-page {
  gap: 1.5rem !important;
}
.universsity-page .uni_card {
  width: 16rem;
}
.scholarship-page {
  margin-top: 0px !important;
  padding-top: 0;
}
.navbar-toggler {
  font-size: 16px !important;
  color: #ff5573;
  background-color: transparent;
  border: 1px solid #ff5573;
}
.navbar-fiexd {
  position: fixed;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 8px rgb(0 0 0 / 6%);
  z-index: 9999;
}
.f_menu h5 {
  font-weight: 500 !important;
  font-size: 18px;
  margin-bottom: 20px;
}
.logo {
  width: 9rem;
}
.profile {
  width: 30px;
  height: 30px;
}
a {
  text-decoration: none;
  color: inherit;
}
.nav-link {
  font-size: 18px;
  font-weight: 600;
  line-height: 29.71px;
  letter-spacing: -0.2px;
  text-align: left;
  padding: 0 25px !important;
  color: rgba(51, 51, 51, 1) !important;
}
.nav-link:hover, .nav-link:focus, .nav-link:active {
  color: #ff5573 !important;
}
.home-page-container {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  max-width: 100%;
}
.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content {
  width: 50%;
}
.title-home {
  font-size: 2.6rem;
  font-weight: 700;
  color: #ff5573;
  line-height: 55px;
}
.subtitle {
  color: #696969;
}

.detail_button {
  border: 1px solid #FFFFFF;
  padding: 0.6rem 2rem;
  border-radius: 60px;
  background-color: white;
  margin-right: 20px;
  box-shadow: 0px 4px 12px 0px #9999995C;
}
.active-tab-course {
  background-color: #FF5573 !important;
  color: #fff !important;
}

.image-container-2 {
  width: 70%;
  text-align: right;
  /* padding: 3rem 0 0rem 3rem; */
}
.main-image {
  max-width: 100%;
  height: auto;
  width: 100%;
}
.search_container {
  background-color: #FFF0F0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 4rem; */
  border-radius: 12px;
  padding: 1.5rem;
}
.what-we-can-do::before {
  position: absolute;
  content: "";
  background: url("./assets/plan-icon.png") no-repeat;
  right: 0;
  z-index: 999;
  width: 300px;
  height: 100px;
}
.section_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  background-color: yellow;
}
.section_inner > div {
  margin: 0;
  padding: 0;
  height: 100%;
  border-right: 1px solid #a9a9a9;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.search_btn {
  background-color: #ff5573;
  border: none;
  padding: 1rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.search_container h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.search_container input {
  border: none;
  outline: none;
  background: transparent;
  font-size: 18px;
  height: 100%;
  width: 100%;
}
.search_icon {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.what-we-can-do {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 0px;
  border-bottom: 1.5px solid rgb(228 228 228);
}
.what-we-can-do-title {
  color: #ff5573;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 2rem;
}
.what-we-can-do-description {
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
  font-size: 18px;
  letter-spacing: 0.5px;
}
.what-we-can-do-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.4rem;
  margin: 3rem 0;
}
.frame-component {
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgb(249 159 174 / 33%);
  padding: 1rem;
  gap: 0rem;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.frame-component.rounded h6 {
  font-size: 18px;
  color: #000;
}
.frame-component-image {
  width: 100%;
  max-width: 230px;
  height: auto;
}
.frame-component.rounded {
  border-radius: 25px !important;
}
.frame-component-button {
  background-color: #ffff;
  font-weight: 500 !important;
  color: #00949b;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.countries-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}
.countries {
  width: 21.5rem;
  border-radius: 0.7rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.countries-image {
  width: 100%;
}
.countries-title {
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
}
.view-all-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.view-all-button .button {
  background-color: #ff5573;
  color: white;
  border: none;
  padding: 6px 10px 6px 20px;
  border-radius: 0.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
}
.button-icon {
  margin-left: 0.5rem;
}
.featured-university-container {
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #d3d3d3;
}
.br-t-0 {
  border-top: 0px !important;
}
.countries-container.gap-1.my-5 {
  gap: 1.8rem !important;
}
.countries-container.gap-1.my-5 .uni_card {
  width: 15.8rem;
}
.how-to-work .countries {
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.how-to-work .card-content-how-it-work {
  text-align: center;
}
.card-content-how-it-work h6 {
  font-size: 18px;
}
.how-to-work .countries img {
  width: 70%;
  margin-bottom: 25px;
}
.university-card {
  cursor: pointer;
  border-radius: 4px;
  width: calc(21.25% - 0.8%);
  display: flex;
  flex-direction: column;
}
.university-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.card-info {
  padding: 16px;
  display: flex;
  width: 100%;
  font-weight: 500 !important;
  justify-content: space-between;
  align-items: center;
}
.university-logo {
  height: 50px;
  width: 50px;
  background-color: #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}
.university-name {
  font-size: 1rem;
  font-weight: bold;
}
.university-location {
  font-size: 0.875rem;
}
.card-content-how-it-work {
  padding: 10px 14px;
}
.what-student-says-container-what-student {
  max-width: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}
.title-what-student {
  font-size: 2rem;
  color: #333;
  width: 100%;
  text-align: left;
}
.description-what-student {
  font-size: 1rem;
  color: #666;
  text-align: left;
  margin-bottom: 32px;
  width: 100%;
}
.content-container-what-student {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.video-container-what-student {
  cursor: pointer;
}
.video-thumbnail-what-student {
  width: 100%;
  height: 400px;
  border-radius: 20px !important;
}
.youtube-video-what-student {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.reviews-container-what-student {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 50%;
  max-width: 1028px;
}
.student-review-card-what-student {
  display: flex;
  gap: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.student-image-what-student {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.review-content-what-student {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.student-name-what-student {
  font-size: 1.2rem;
}
.stars-what-student {
  display: flex;
  gap: 4px;
  margin: 5px 0;
}
.star-image-what-student {
  width: 16px;
  height: 16px;
}
.student-review-what-student {
  font-size: 0.9rem;
  text-align: left;
}
.faq_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.faq {
  width: 50%;
}
.my-faq-accordian {
  box-shadow: none !important;
}
.faq_img {
  width: 30%;
}
.footer {
  background-color: #ff5573;
  color: white;
  font-weight: 400;
}
.inner_footer {
  display: flex;
  justify-content: space-between;
}
.f_logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.foot_img {
  width: 8rem;
}
.foot_img img {
  width: 100%;
  object-fit: cover;
}
.f_menu {
  margin-top: 2rem;
}
.f_menu ul {
  margin: 0px;
  padding: 0px 0px 0px 25px;
}
.f_menu ul li {
  margin-bottom: 0.5rem;
  font-size: 16px;
}
.f_contact {
  width: 2rem;
  height: 2rem;
  display: flex;
  border-radius: 27%;
  padding: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid white;
}
.f_contact img {
  width: 15px;
  height: 15px;
}
p {
  margin-bottom: 0 !important;
}
.course_head {
  background-color: rgb(255 240 240);
  padding: 10px;
  border-radius: 4px;
  margin-top: 1rem;
  width: 20rem;
}
.course_head_new {
  background-color: rgb(255 240 240);
  padding: 7px;
  border-radius: 20px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  width: fit-content;
}
.min_height {
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right_list {
  background-color: rgb(255 240 240);
  padding: 2rem;
  border-radius: 10px;
  margin-top: 2rem;
}
.pull-right {
  float: right;
}
.course_list {
  box-shadow: none !important;
  border: none !important;
  background-color: white !important;
  width: 18rem !important;
  padding: 1rem 10px;
  margin-top: 0.6rem;
}
.course_card {
  padding: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}
.course_card2 {
  padding: 1.5rem;
  border-radius: 15px;
}
.course_card h4 {
  font-size: 16px;
  margin-bottom: 15px;
}
.course_card .inner_card p {
  font-size: 14px;
}
.compare_course {
  display: flex;
  justify-content: flex-end;
}
.inner_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.inner_card_2 {
  justify-content: space-between;
  align-items: center;
}
.right_scholar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.s_img_card img {
  width: 60%;
}
.s_img_card {
  background-color: #f3f3f33b;
  padding: 1rem;
  display: flex;
  border-radius: 18px;
  flex-direction: column;
  box-shadow: 0px 2.67px 8.01px 0px rgba(0, 0, 0, 0.11);
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
}
.s_img_card ul li {
  padding: 10px 0;
  border-bottom: 1px solid #00000054;
  font-size: 16px;
  text-align: left;
}
.s_img_card2 {
  background-color: #f3f3f33b;
  padding: 1rem;
  display: flex;
  border-radius: 18px;
  flex-direction: column;
  box-shadow: 0px 2.67px 8.01px 0px rgba(0, 0, 0, 0.11);
}
.s_img_card ul {
  padding-left: 0px !important;
}
.side-bar-img img {
  width: 120px !important;
}
.hilight-danger {
  color: #ff5573;
}
.blog-card {
  width: 100% !important;
  margin-bottom: 20px !important;
}
.ielts_container {
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./assets/ilets-exam.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  color: black;
}
.sop_container {
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./assets/sop.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  color: black;
}
.lor_container {
  width: 100%;
  height: 60vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("./assets/lor.png");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  color: black;
}
.insti_img {
  width: 100%;
}
.c_category {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
.exam-bar img {
  width: 40px;
}
.right_scholar.exam-bar h4 {
  font-size: 15px;
}
.ielts_content h1 {
  font-size: 45px;
  font-weight: 600;
  letter-spacing: 2px;
}
.uni_card {
  width: 18rem;
}
.breadcrumb {
  background-color: #fff !important;
}
.custom-checkbox {
  border: 2px solid #FF5573 !important;
  border-radius: 5px;
  cursor: pointer;
}
.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #FF6477 !important;
  border-radius: 10px !important;
  margin-top: 5px !important;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: #fff !important;
}
.overview-container {
  border: 1px solid #ccc;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
}
.main-container {
  width: 100%;
}
.heading {
  margin-bottom: 20px;
}
.formField {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.formField label {
  flex: 1;
  text-align: left;
  margin-right: 10px;
}
.file-upload input[type="file"] {
  display: none;
}
.file-upload input[type="text"] {
  flex: 4;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
input[type="text"],
textarea {
  flex: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.serch-profile {
  background: #ffc8ce;
  padding: 20px;
  border-radius: 14px;
  position: relative;
  height: 150px;
  margin-bottom: 2rem;
}
.serch-profile button {
  border: 1.5px solid #ff5573;
  background: #fff;
  color: #ff5573 !important;
  text-align: center;
  text-decoration: none;
  position: absolute;
  left: 43%;
}
textarea {
  resize: vertical;
}
.page-heading-title {
  font-family: "Lato", sans-serif;
font-weight: 700;
  font-size: 30px;
  font-weight: 500 !important;
  line-height: 44px;
  text-align: left;
}
.AddMore {
  color: #FF5573;
}
.bg-highlight {
  background-color: #FF5573;
}
.text-highlight {
  color: #FF5573;
}
.unique-modal-form {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 400px;
}
.unique-modal-form-header {
  background-color: #ff8499;
  color: white;
}
.unique-modal-form-body {
  background-color: #ff8499;
  color: white;
}
.unique-modal-form-main .unique-modal-form-input {
  margin-bottom: 10px;
  border-radius: 5px;
}
.unique-modal-form-check {
  user-select: none;
}
.unique-modal-form-button {
  background-color: #fff !important;
  color: #ff8499 !important;
  border: none;
}
.modal-dialog {
  position: fixed;
  bottom: 0px;
  width: 400px;
  right: 10px;
}
.nav-link {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-weight: 500 !important;
}

/* Responsive Styles */
/* @media (min-width: 1200px) {
  .container {
    max-width: 1242px !important;
    margin: 0 auto;
    padding: 0 15px;
  }
} */
@media (max-width: 1200px) {
  .course_card {
    min-width: 300px;
    width: 100%;
  }
  .inner_course {
    flex-direction: column;
    gap: 1rem;
  }
  .course_list {
    width: 100% !important;
  }
  .right_list {
    width: 100% !important;
  }
  .content-container-what-student {
    flex-direction: column;
  }
  .video-container-what-student {
    display: none;
  }
  .reviews-container-what-student {
    width: 100%;
  }
  .section_inner {
    flex-direction: column;
    gap: 2rem;
    padding: 10px;
    align-items: start;
    width: 100%;
  }
  .search_btn {
    width: 100%;
    border-radius: 5px;
  }
  .section_inner > div {
    border-right: none;
  }
  .ielts_container {
    height: 50vh;
  }
  .ielts_content h1 {
    font-size: 50px;
  }
}
@media (max-width: 900px) {
  .inner_footer {
    flex-direction: column;
  }
  .f_logo {
    align-items: start;
  }
}
@media (max-width: 768px) {
  .uni_card {
    width: 100%;
  }
  .countries {
    width: 100%;
  }
  .countries > img {
    width: 100%;
  }
  .profile {
    width: 2rem;
    height: 2rem;
    margin-left: 25px;
  }
  .inner_card {
    flex-direction: column;
    align-items: start;
  }
  .course_head {
    width: 100%;
  }
  .compare_course {
    display: flex;
    justify-content: center;
  }
  .faq_img {
    display: none;
  }
  .faq {
    width: 100%;
  }
  .reviews-container-what-student {
    grid-template-columns: 1fr;
  }
  .video-thumbnail-what-student {
    height: 450px;
  }
  .what-we-can-do-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .what-we-can-do-title {
    font-size: 1.6rem;
  }
  .search_container h3 {
    font-size: 1.5rem;
  }
  .title-home {
    font-size: 1.6rem;
  }
  .content {
    width: 100%;
  }
  .image-container {
    /* display: none; */
  }
  .search_container {
    padding: 0.4rem;
  }
  .search_icon {
    width: 100%;
    height: 4rem !important;
  }
  .c_category {
    flex-direction: column;
    gap: 1rem;
  }
  .c_category > button {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .award_imgs img {
    width: 90%;
  }
  .what-we-can-do-grid {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 300px) {
  .course_card {
    min-width: 250px;
    padding: 1rem;
  }
  .ielts_content h1 {
    font-size: 30px;
  }
  .ielts_content h2 {
    font-size: 20px;
  }
  .explore-button {
    padding: 1rem;
  }
  .course_card h4 {
    font-size: 16px;
  }
}
input[type="date"] {
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 65%;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  border: 2px solid #FF5573 !important;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1242px !important;
  }
}

@media (max-width: 1200px) {
  .course_card {
    min-width: 300px;
    width: 100%;
  }
  .inner_course {
    flex-direction: column;
    gap: 1rem;
  }
  .course_list {
    width: 100% !important;
  }
  .right_list {
    width: 100% !important;
  }
  .content-container-what-student {
    flex-direction: column;
  }
  .video-container-what-student {
    display: none;
  }
  .reviews-container-what-student {
    width: 100%;
  }
  .section_inner {
    flex-direction: column;
    gap: 2rem;
    padding: 10px;
    align-items: start;
    width: 100%;
  }
  .search_btn {
    width: 100%;
    border-radius: 5px;
  }
  .section_inner > div {
    border-right: none;
  }
  .ielts_container {
    height: 50vh;
  }
  .ielts_content h1 {
    font-size: 35px;
  }
}

@media (max-width: 900px) {
  .inner_footer {
    flex-direction: column;
  }
  .f_logo {
    align-items: start;
  }
}

@media (max-width: 768px) {
  .uni_card {
    width: 100%;
  }
  .countries {
    width: 100%;
  }
  .countries > img {
    width: 100%;
  }
  .profile {
    width: 2rem;
    height: 2rem;
    margin-left: 25px;
  }
  .inner_card {
    flex-direction: column;
    align-items: start;
  }
  .course_head {
    width: 100%;
  }
  .compare_course {
    display: flex;
    justify-content: center;
  }
  .faq_img {
    display: none;
  }
  .faq {
    width: 100%;
  }
  .reviews-container-what-student {
    grid-template-columns: 1fr;
  }
  .video-thumbnail-what-student {
    height: 450px;
  }
  .what-we-can-do-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .what-we-can-do-title {
    font-size: 1.6rem;
  }
  .search_container h3 {
    font-size: 1.5rem;
  }
  .title-home {
    font-size: 1.6rem;
  }
  .content {
    width: 100%;
  }
  .image-container {
    /* display: none; */
  }
  .search_container {
    padding: 0.4rem;
  }
  .search_icon {
    width: 100%;
    height: 4rem !important;
  }
  .c_category {
    flex-direction: column;
    gap: 1rem;
  }
  .c_category > button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .award_imgs img {
    width: 90%;
  }
  .what-we-can-do-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 300px) {
  .course_card {
    min-width: 250px;
    padding: 1rem;
  }
  .ielts_content h1 {
    font-size: 30px;
  }
  .ielts_content h2 {
    font-size: 20px;
  }
  .explore-button {
    padding: 1rem;
    font-size: 12px;
  }
  .course_card h4 {
    font-size: 16px;
  }
}

input[type="date"] {
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 65%;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  border: 2px solid #FF5573 !important;
}
.countries-container-2 {
  display: flex;
  flex-wrap: nowrap;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .container {
    max-width: 1060px !important;
  }
  .countries-container-2 {
    display: flex;
    /* flex-wrap: nowrap; */
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
}
.university-image {
  width: 100% !important;
  height: auto;
  object-fit: cover;
}

.uni_card {
  width: 15rem;
}

}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: #fff !important;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}
.trending-courses-carousel {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.course-card {
  width: 100%;
  max-width: 280px;
  margin: auto;
  border-radius: 10px;
}

.course-card-image {
  height: 200px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;

}

.course-card-content {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 10px;

  color: #fff;
}

.view-details-button {
  background-color: #FF5573;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}


.dropdown-css{
  width: 280px;
    padding: 10px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
}

.tab-link {
  color: black;
}

.tab-link.active-tab {
  color: #FF5573 !important;
}
a:hover {
  color: inherit;
  text-decoration: none;
}


.select__value-container{
  max-height: 35px;
  overflow: hidden !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 10px 15px !important;
  }

  .home-page-container {
    padding-top: 2rem;
  }

  .title-home {
    font-size: 2rem;
    line-height: 40px;
  }

  .search_container {
    margin-top: 2rem;
    padding: 1rem;
  }

  .search_container h3 {
    font-size: 1.2rem;
  }

  .what-we-can-do {
    padding: 2rem 0;
  }

  .what-we-can-do-title {
    font-size: 1.8rem;
  }

  .what-we-can-do-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 2rem 0;
  }

  .countries-container {
    flex-direction: column;
    gap: 1rem;
  }

  .countries {
    width: 100%;
  }

  .university-card {
    width: 100%;
  }

  .faq_container {
    flex-direction: column;
  }

  .faq {
    width: 100%;
  }

  .inner_footer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .f_menu {
    margin-top: 1rem;
  }

  
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23ff5573' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
@media (max-width: 767px) {
  .university-carousel-item {
    flex: 0 0 auto;
    width: 80%;
    scroll-snap-align: start;
  }

  .university-image {
    height: 150px;
  }

  .university-name {
    font-size: 16px;
  }

  .university-location {
    font-size: 14px;
  }

  .university-logo {
    height: 40px;
    width: 40px;
  }
}

.university-carousel-container {
  position: relative;
  margin-top: 20px;
}

.university-carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.university-carousel::-webkit-scrollbar {
  display: none;
}

.university-carousel-item {
  flex: 0 0 auto;
  width: 25%;
  scroll-snap-align: start;
  margin-right: 10px;
}

.university-image {
  height: 200px;
  width: 100%;
  object-fit: contain;
  background: #fff;
}

.university-name {
  font-size: 18px;
}

.university-location {
  font-size: 16px;
}

.university-logo {
  background: none;
}

.university-logo img {
  height: auto;
  width: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff5573;
  border: none;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}

.left-arrow {
  left: -55px;
}

.right-arrow {
  right: -55px;
}

@media (max-width: 767px) {
  .university-carousel-item {
    width: 80%;
  }

  .university-image {
    height: 150px;
  }

  .university-name {
    font-size: 16px;
  }

  .university-location {
    font-size: 14px;
  }

  .university-logo {
    height: 40px;
    width: 40px;
  }
}


.work-cards-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.work-card {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  text-align: center;
}

.work-card img {
  width: 100%;
  max-width: 200px;
  margin-bottom: 10px;
}

.work-card h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.work-card p {
  font-size: 14px;
}

@media (max-width: 767px) {
  .work-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .work-card {
    width: 100%;
    max-width: 300px;
  }
}

@media (max-width: 767px) {
  .student-review-card-what-student {
    display: flex;
    gap: 10px;
    width: 325px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
}

.home-page-container {
  padding-top: 3rem;
}

.top-section {
  margin-bottom: 2rem;
}

.content {
  text-align: center;
}

.title-home {
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 55px;
}

.subtitle {
  color: #696969;
}

.explore-button {
  background-color: #ff5573;
  color: white;
  border: none;
  padding: 7px 7px;
  border-radius: 5px;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 500 !important;
  font-size: 18px;
}

.image-container {
  text-align: center;
  /* margin-top: 2rem; */
}

.main-image {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .content {
    text-align: left;
  }

  .image-container {
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .section_inner {
    flex-direction: column;
    gap: 2rem;
    padding: 38px !important;
    align-items: start;
    width: 100%;
}

.select__indicator{
  display: none;
}
  .section_inner > div {
      border-right: none;
      width: 99%;
  }
  .css-19pu2p8-control{
    border-right: none;
    width: 210px !important;
  }
}

.inner_footer{
  display: flex;
  justify-content: end !important;

}

@media (max-width: 768px) {
  .footer .inner_footer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
  }

  .footer .f_menu {
    margin-bottom: 20px;
  }

  .footer .f_menu h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .footer .f_menu ul {
    padding-left: 0;
  }

  .footer .f_menu ul li {
    margin-bottom: 5px;
    text-decoration: none;
    list-style: none;
  }
 
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.f_menu {
  flex: 1 1 200px;
  margin-right: 20px;
}

@media (min-width: 769px) {
  .footer-content {
    flex-wrap: nowrap;
  }
}




@media (max-width: 767px) {
  .career-path-header {
    flex-direction: column;
    align-items: stretch !important;
  }

  .career-path-header h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .career-path-header .input-group {
    width: 100%;
  }

  .career-path-header .input-group input {
    font-size: 0.9rem;
  }

  .career-path-header .btn {
    padding: 0.375rem 0.75rem;
  }
}

@media (min-width: 768px) {
  .career-path-header .w-md-auto {
    width: auto !important;
  }
}

@media (max-width: 767px) {
  .welcome-card .card-body {
      padding: 1rem;
  }

  .welcome-card .card-text {
      font-size: 0.9rem;
  }

  .welcome-card .card-body span {
      font-size: 0.85rem;
      word-break: break-word;
  }
}
@media (max-width: 575px) {
  .d-flex.flex-column.flex-sm-row {
    width: 100%;
  }
  
  .d-flex.flex-column.flex-sm-row .badge,
  .d-flex.flex-column.flex-sm-row .btn {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .home-page-container {
    padding-top: 1rem;
  }

  .top-section .row {
    flex-direction: column-reverse;
  }

  .image-container {
    text-align: center;
    margin-bottom: 2rem;
  }

  .main-image {
    max-width: 80%;
    height: auto;
  }

  .content {
    text-align: center;
  }

  .title-home {
    font-size: 2rem;
    line-height: 1.2;
  }

  .subtitle {
    font-size: 1rem;
  }

  .explore-button {
    padding: 0.5rem 1rem;
  }
}

.img-fluid-login {
  max-width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .img-fluid-login {
    max-width: 55%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.f_menu {
  flex: 1 1 200px;
  margin-right: 20px;
}

@media (min-width: 769px) {
  .footer-content {
    flex-wrap: nowrap;
  }

}

/* .f_logo {
  margin-right: 20px;
} */

.foot_img {
  margin-right: 20px;
}


.d-flex.align-items-center.gap-3.mt-4 {
  gap: 1rem !important; /* Reduce from 3rem */
  margin-top: 1rem !important; /* Reduce from 4rem */
}

.edu-footer {
  background-color: #ff5573;
  color: white;
  padding: 2rem 1rem;
  font-family: "Lato", sans-serif;
  font-weight: 500 !important;}

.edu-footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.edu-footer-main {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  flex: 1 1 100%;
}

.edu-footer-logo-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.edu-logo {
  width: 120px;
  margin-bottom: 1rem;
}

.edu-social-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.edu-social-title {
  font-size: 14px;
  margin-bottom: 0.5rem;
}

.edu-social-icons {
  display: flex;
  gap: 1rem;
}

.edu-social-icons a {
  color: white;
}

.edu-footer-contact {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.edu-contact-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.edu-footer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  flex: 2 1 60%;
}

.edu-link-column {
  flex: 1 1 150px;
}

.edu-link-column h5 {
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.edu-link-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.edu-link-column li {
  margin-bottom: 0.25rem;
}

.edu-link-column a {
  color: white;
  text-decoration: none;
}

.edu-footer-copyright {
  text-align: center;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
  .edu-footer-content {
    flex-direction: column;
  }

  .edu-footer-main {
    flex-direction: column;
  }

  .edu-footer-links {
    flex-direction: row;
    justify-content: space-between;
  }

  .edu-link-column {
    flex-basis: calc(50% - 1rem);
  }
}

.f_menu {
  flex: 1 1 150px !important; /* Reduced from 200px */
  margin-right: 10px !important; /* Reduced from 20px */
}

.inner_footer {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.footer-content {
  display: flex !important;
  justify-content: space-between !important;
  /* align-items: flex-start !important; */
}
@media (min-width: 768px) {
  .footer-content{
    /* padding-left: 150px !important;
    padding-right: 150px !important;; */
  }
}
@media (max-width: 768px) {
  .dropdown-css {
    width: 235px !important;
    padding: 10px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
  }
}
@media (max-width: 768px) {
  .award_imgs {
    display: flex !important;
    justify-content: center !important;
  }
  .active-tab-course{
    margin-bottom: 10px !important;
  }

  .carrer-responsive-img{
    height: 300px !important;
  }
  .carrer-responsive-text{
    font-size: 1.7rem !important;
    margin-top: 21px !important;  
  }
  .carrer-responsive-text-inner{
    font-size: 1rem !important;
    margin-bottom: 21px !important;  
  }

  .active-tab-res{
    display: flex !important;
    justify-content: center !important;

  }

  .image-download-nutton-cut{
    margin-bottom: 290px !important;
  }
}
.f_logo {
  flex: 0 0 auto !important;
  margin-right: 2rem !important;;
}


.edulley-footer {
  background-color: #ff5573;
  color: white;
  padding: 2rem 1rem;
  font-family: "Lato", sans-serif;
font-weight: 500 !important;
}

.edulley-footer__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.edulley-footer__main {
  flex: 1 1 250px;
}

.edulley-footer__logo {
  width: 120px;
  margin-bottom: 1rem;
}

.edulley-footer__social-icons {
  display: flex;
  gap: 1rem;
}

.edulley-footer__contact {
  margin-top: 1rem;
}

.edulley-footer__contact-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.edulley-footer__links {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  flex: 2 1 500px;
}

.edulley-footer__column {
  flex: 1 1 120px;
}

.edulley-footer__column-title {
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.edulley-footer__column-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.edulley-footer__column-item {
  margin-bottom: 0.25rem;
  white-space: nowrap;
}

.edulley-footer__copyright {
  width: 100%;
  text-align: center;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

@media (max-width: 768px) {
  .edulley-footer__links {
    flex-basis: 100%;
  }
  
  .edulley-footer__column {
    flex-basis: calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .edulley-footer__column {
    flex-basis: 100%;
  }
}

.footer-column {
  margin-right: 10px !important;
}

@media (min-width: 768px) {
  .footer-column {
    flex: 0 1 auto !important;
    margin-right: 20px !important;
  }
}
@media (max-width: 768px) {
  .responsive-mobile-div {
    margin-bottom: 22px !important;
  }
  .mobile-res-course-card{
    margin-top: 15px !important;
    display: flex !important;
    justify-content: flex-start !important;
  }
}


.f_logo {
  flex: 0 0 auto !important;
  margin-right: 8rem !important;
}

.f_menu:not(.footer-column) {
  flex: 1 1 200px !important;
  margin-right: 20px !important;
}

.explore-button {
  background-color: #ff5573 !important; /* Adjust color as needed */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-family: "Lato", sans-serif;
  font-weight: 500 !important;
    font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.explore-button:hover {
  background-color: #ff3a5c; /* Slightly darker shade for hover effect */
}

.font-lato {
  font-family: "Lato", sans-serif;
}

.shine-effect {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  animation: shine 1.5s infinite;
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

